import './App.css';
import { css } from 'aphrodite';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
	useRecoilState,
	useRecoilValue,
} from 'recoil';
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'reactstrap';
import { authStateAtom } from './models/atoms/auth_atoms';
import { accountCompanyInfoAtom, userAccountAtom } from './models/atoms/account_atoms';
import { languageAtom, navStateAtom, dashboardVersionAtom } from './models/atoms/ui_atoms';
import { memo } from "react";
import { device_controller } from './controllers/device_controller';
import { auth_controller } from './controllers/auth_controller';
import { account_controller } from './controllers/account_controller';
import { company_controller } from './controllers/company_controller';
import { sizes } from './views/styles/style_variables'
import { LoginPage } from './views/pages/login'
import { DeviceMetricsPage } from './views/pages/device_metrics';
import { AccountsPage } from './views/pages/accounts';
import { SettingsPage } from './views/pages/settings';
import { appStyles } from './views/styles/app_styles';
import { TranslationProvider, useTranslation } from './contexts/TranslationContext'; // Ensure this is imported
// @ts-ignore
import worldIcon from './assets/world-icon.png'
// @ts-ignore
import germany from './assets/germany.png'
// @ts-ignore
import uk from './assets/uk.png'
// @ts-ignore
import us from './assets/us.png'
import { dashboardVersionInterface } from './models/interfaces/ui_interfaces';
import FirstLoginModal from './views/components/FirstLoginModal';
import { BatteryLife } from './views/components/BatteryLife';
import { MobileNavMenu } from './views/components/navbar/MobileNavMenu';
import { NavButton } from './views/components/navbar/NavButton';
const logo = require("./assets/logo.png") as string;

export type NavState = 'device-metrics' | 'accounts-page' | 'settings-page' | 'battery-calculator';

export const MainPage = memo(() => {
	const [navState, setNavState] = useRecoilState<NavState>(navStateAtom);
	const account = useRecoilValue(userAccountAtom);
	const company_info = useRecoilValue(accountCompanyInfoAtom);
	const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - sizes.header;
	const pageHeight = ((Math.floor(vh) - sizes.header).toString() + "px");
	const { t, changeLanguage, language } = useTranslation(); // Use the translation hook
	const [showDropdown, setShowDropdown] = useState(false);
	const dashboardVersion = useRecoilValue<dashboardVersionInterface>(dashboardVersionAtom);
	const [languageState, setLanguageState] = useRecoilState<string>(languageAtom);
	const languageMenu = useRef<HTMLInputElement>(null);

	const toggleDropdown = () => {
		setShowDropdown(show => {
			return !show;
		});
	};

	function logout() {
		auth_controller.logout();
		window.history.pushState({}, '', '/');
	}

	const showAccountPage = account != null && account.permissions.manage_accounts;

	useEffect(() => {
		changeLanguage(languageState)
	}, [languageState])

	const loadData: any = () => {
		company_controller.get_device_settings();
		company_controller.get_weathermode_settings();
		company_controller.get_roc_settings();
		company_controller.get_low_power_settings();
	};

	var page = <></>;

	const toggleLanguage = (lang: string) => {
		changeLanguage(lang);
	};

	useEffect(() => {
		const disableDropdown = (e: MouseEvent) => {
			if (languageMenu.current && !languageMenu.current.contains(e.target as HTMLElement)) {
				setShowDropdown(false);
			}
		};
		document.addEventListener('mousedown', disableDropdown);

		return () => {
			document.removeEventListener('mousedown', disableDropdown);
		};
	}, []);

	console.log(window.location);
	if (window.location.pathname === '/battery_life') {
		return <div style={{ display: 'flex', height: "100vh", justifyContent: 'center', alignItems: 'center', backgroundColor: '#e3eefa' }}><BatteryLife /></div>;
	}

	switch (navState) {
		case 'device-metrics':
			page = <DeviceMetricsPage style={{ height: "100%" }}></DeviceMetricsPage>;
			break;
		case 'accounts-page':
			page = <AccountsPage style={{ height: "100%" }}></AccountsPage>;
			break;
		case 'settings-page':
			loadData();
			page = <SettingsPage style={{ height: "100%" }}></SettingsPage>;
			break;
	}

	return (
		<div className={css(appStyles.app_style)} style={{ height: "100vh", overflow: 'hidden' }}>
			<div className={css(appStyles.nav_container)}>
				<img style={{ width: 55 }} src={logo} alt="Logo" onClick={() => setNavState('device-metrics')}></img>
				<div style={{ color: 'white', fontSize: '1.4rem', marginRight: 'auto', marginLeft: '0.5rem' }}>   {company_info ? t('Welcome', { company: company_info.name }) : ''}</div>
				<div className={css(appStyles.nav_bar_styles)}>
					<NavButton
						isActive={navState === 'device-metrics'}
						onClick={() => setNavState('device-metrics')}
					>
						{t('Devices')}
					</NavButton>
					{
						showAccountPage
							? <NavButton
								isActive={navState === 'accounts-page'}
								onClick={() => setNavState('accounts-page')}
							>
								{t('Accounts')}
							</NavButton>
							: <></>
					}
					<NavButton
						isActive={navState === 'settings-page'}
						onClick={() => setNavState('settings-page')}
					>
						{t('Settings')}
					</NavButton>
					<NavButton
						isActive={false}
						onClick={() => { auth_controller.logout(); window.history.pushState({}, '', '/'); }}
					>
						{t('Logout')}
					</NavButton>
					<div className={css(appStyles.language_anchor)}>
						<div className={css(appStyles.language_selector)} onClick={toggleDropdown}>
							<img
								src={worldIcon}
								alt="World Icon"
								className={css(appStyles.language_icon)}
							/>
						</div>
						{showDropdown && (
							<div ref={languageMenu} className={css(appStyles.language_dropdown)}>
								<img
									src={germany}
									alt="German Flag"
									onClick={() => {
										toggleLanguage('de');
										toggleDropdown();
									}}
									style={{ cursor: 'pointer', width: '40px', height: '40px' }} // Adjust these values
								/>
								<img
									src={uk}
									alt="UK Flag"
									onClick={() => {
										toggleLanguage('en-GB');
										toggleDropdown();
									}}
									style={{ cursor: 'pointer', width: '40px', height: '40px', margin: '15%' }} // Adjust these values
								/>
								<img
									src={us}
									alt="US Flag"
									onClick={() => {
										toggleLanguage('en-US');
										toggleDropdown();
									}}
									style={{ cursor: 'pointer', width: '40px', height: '40px', margin: '15%' }} // Adjust these values
								/>
							</div>
						)}
					</div >
				</div >
				<MobileNavMenu
					navState={navState}
					setNavState={setNavState}
					showAccountPage={showAccountPage}
					logout={logout}
					toggleLanguage={toggleLanguage}
				/>
			</div >


			<div style={{ height: pageHeight }}>{page}</div>
			<FirstLoginModal />
			<div className={css(appStyles.footer_styles)}>
				<a href='https://device-service.manhole-metrics.com/redirect-to-privacy-and-cookies'>
					<p style={{ color: 'white', paddingTop: 15 }}>{t('Privacy + Cookies')}</p>
				</a>
				<i className="bi bi-dot"></i>
				<a href='https://device-service.manhole-metrics.com/redirect-to-terms-of-use'>
					<p style={{ color: 'white', paddingTop: 15 }}>{t('Terms Of Use')}</p>
				</a>
				<i className="bi bi-dot"></i>
				<p style={{ color: 'white', paddingTop: 15, paddingRight: 10 }}>{t('Support')}:</p>
				<a href={`mailto:support@manholemetrics.com`} className={css(appStyles.icons)}><i className="bi bi-envelope"></i></a>
				<i className="bi bi-dot"></i>
				<a href='https://wa.me/02080505393' className={css(appStyles.icons)}><i className="bi bi-whatsapp"></i></a>
				<i className="bi bi-dot"></i>
				<p style={{ color: 'white', paddingTop: 15, paddingRight: 10 }}>{t('Version') + ' ' + process.env.REACT_APP_VERSION}</p>
			</div>
		</div >
	);
});

const FilterStateComponent = memo(_ => {
	return <MainPage />;
});

function App() {
	const auth = useRecoilValue(authStateAtom);
	if (!auth.validToken) {
		auth_controller.check_token();
	} else {
		device_controller.get_device_list();
		account_controller.get_company_name();
		account_controller.get_account_list();
		account_controller.get_user_account();
		company_controller.setup();
	}

	// TODO: refactor quick & dirty fix for battery_life
	if (!auth.validToken && window.location.pathname !== '/battery_life') {
		return (
			<div className={css(appStyles.app_style)} style={{ height: "100vh" }}>
				<TranslationProvider>
					<LoginPage />
				</TranslationProvider>
			</div>
		);
	}
	return (
		<TranslationProvider>
			<FilterStateComponent />
		</TranslationProvider>
	);
}

export default App;
