import { StyleSheet } from 'aphrodite';
import { colors, sizes } from './style_variables';

export const mobileBreakPoint = 719;
const mobileBreakpointQuery = `@media (max-width: ${mobileBreakPoint}px)`;

export const appStyles = StyleSheet.create({
    app_style: {
      textAlign: "left",
      backgroundColor: colors.light_grey,
    },
    nav_container: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      background: colors.blue,
      padding: '0.25rem 1rem',
    },
    nav_anchor: {
      position: 'relative',
    },
    nav_button_styles: {
      borderColor: '#aad8e2',
      height: 40,
      margin: 0,
      [mobileBreakpointQuery]: {
        width: '100%',
      },
    },
    nav_header_styles: {
      marginRight: 20,
      marginLeft: 10
    },
    nav_bar_styles: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      gap: 10,
      [mobileBreakpointQuery]: {
        display: 'none',
      },
    },
    nav_mobile_menu_button: {
      display: 'none',
      fontSize: '3rem',
      width: 'auto',
      minWidth: 'auto',
      height: 40,
      padding: 0,
      [mobileBreakpointQuery]: {
        display: 'inline-flex',
      },
    },
    nav_mobile_menu_content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      width: '50vw',
      padding: '1rem',
    },
    nav_mobile_language_dropdown: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      margin: '10 auto',
    },
    nav_mobile_language_dropdown_deactivated: {
      display: 'none',
    },
    footer_styles: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: sizes.header,
      background: colors.blue,
      margin: 0,
      paddingTop: 5,
      paddingRight: 20,
      bottom: '0 !important',
      position: 'fixed',
      width: '100%',
	  zIndex: 10,
    },
    icons: {
      fontSize: 25
    },
    current_page: {
      backgroundColor: colors.white,
      color: 'black'
    },
    language_anchor: {
      position: 'relative',
    },
    language_selector: {
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
    },
    language_icon: {
      cursor: 'pointer',
      width: 40,
      height: 40,
      display: 'inline-block',
      borderColor: '#aad8e2',
      [mobileBreakpointQuery]: {
        width: 25,
        height: 25,
      },
    },
    language_dropdown: {
      position: 'absolute',
      margin: '10px 0',
      top: '100%',
      backgroundColor: 'transaparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10,
    },
  });