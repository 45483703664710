import React from 'react';
import { memo, useState } from "react";
import { useRecoilState } from "recoil";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { company_controller } from "../../../controllers/company_controller";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import { Button } from 'reactstrap';
import { useTranslation } from '../../../contexts/TranslationContext';
/* eslint-disable no-useless-escape */

export const BatteryLifeSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	const { t } = useTranslation();

	return <form id='battery_life' className={css(SettingsPageStyles.settings_card)} onSubmit={(e) => {
		e.preventDefault();
	}}>

		<h2 className={css(SettingsPageStyles.card_title)}>{t('Battery Life')}</h2>
		<div className={css(SettingsPageStyles.settings_card_body)}>
			<p className={css(SettingsPageStyles.disclaimer)}>
				{t('To view predicted battery life for given normal operating frequencies for devices with 100% charge, please use our battery life calculator tool available via the button below')}
			</p>
			<Button style={button_styles.main_button} onClick={() => {

				const url = `/battery_life`;
				window.open(url);
			}}>
				{t('Battery Life Calculator')}
			</Button>
		</div>
	</form>

});
