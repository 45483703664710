import React, { ChangeEvent } from 'react';
import { memo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import { changeUserAtom, textAlertErrorAtom } from "../../../models/atoms/account_atoms";
import { changeCompanyDeviceAtom } from "../../../models/atoms/company_atoms";
import { account_controller } from "../../../controllers/account_controller";
import { company_controller } from "../../../controllers/company_controller";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext';
import { parseIncompletePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js/max';
import { isValidNumber } from 'libphonenumber-js';
import { PhoneNumberInput } from './PhoneNumberInput';
import { validateNames, validatePassword, validateNewOldPasswordsMatch, validateRepeatPasswordsMatch } from '../../../utils/account_validations';
/* eslint-disable no-useless-escape */



export const AccountSettingsComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	const { t } = useTranslation();
	const [changeUser, setChangeUser] = useRecoilState(changeUserAtom);
	const times = useRecoilValue(changeCompanyDeviceAtom);
	const [newPassword, setNewPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [curPassword, setCurPassword] = useState('');
	const [nameValidationError, setNameValidationError] = useState('');
	const [phoneValidationError, setPhoneValidationError] = useState('');
	const [passwordValidationError, setPasswordValidationError] = useState('');
	const [matchPasswordsError, setMatchPasswordsError] = useState('');
	const [accountModal, setAccountModal] = useState(false);
	const [passwordModal, setPasswordModal] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState(changeUser?.phone_number || '');

	const isPasswordInvalid = Boolean(!curPassword || !newPassword || !rePassword || passwordValidationError || matchPasswordsError);
	const isChangeUserValid = (changeUser?.change_email_phone || changeUser?.change_first_name || changeUser?.change_last_name) && (!phoneValidationError && !nameValidationError);

	function handleFirstNameChange(e: ChangeEvent<HTMLInputElement>) {
		if (changeUser == null) {
			return;
		}

		const newFirstName = e.target.value;
		setChangeUser({ ...changeUser, first_name: newFirstName });
		setNameValidationError(t(validateNames(newFirstName, changeUser.last_name) ?? ''));
	}

	function handleLastNameChange(e: ChangeEvent<HTMLInputElement>) {
		if (changeUser == null) {
			return;
		}

		const newLastName = e.target.value;
		setChangeUser({ ...changeUser, last_name: newLastName });
		setNameValidationError(t(validateNames(changeUser.first_name, newLastName) ?? ''));
	}

	function validatePasswords(newOldPassword: string, newPassword: string, newRepeatPassword: string) {
		const repeatPasswordError = validateRepeatPasswordsMatch(newPassword, newRepeatPassword);
		if (repeatPasswordError) {
			setMatchPasswordsError(t(repeatPasswordError));
		} else {
			setMatchPasswordsError('');
		}

		const passwordError = validatePassword(newPassword);
		if (passwordError) {
			setPasswordValidationError(t(passwordError));
			return;
		}

		const newOldPasswordError = validateNewOldPasswordsMatch(newPassword, newOldPassword);
		if (newOldPasswordError) {
			setPasswordValidationError(t(newOldPasswordError));
			return;
		}

		setPasswordValidationError('');
	}

	function handleCurPasswordChange(e: ChangeEvent<HTMLInputElement>) {
		const newCurPassword = e.target.value;
		setCurPassword(newCurPassword);
		validatePasswords(newCurPassword, newPassword, rePassword);
	}

	function handleNewPasswordChange(e: ChangeEvent<HTMLInputElement>) {
		const password = e.target.value;
		setNewPassword(password);
		validatePasswords(curPassword, password, rePassword);
	}

	function handleRepeatPasswordChange(e: ChangeEvent<HTMLInputElement>) {
		const newRepeatPassword = e.target.value;
		setRePassword(newRepeatPassword);
		validatePasswords(curPassword, newPassword, newRepeatPassword);
	}

	const handleReset = () => {
		if (newPassword.length < 12) {
			return;
		}
		if (newPassword !== rePassword) {
			return;
		}
		setNewPassword('');
		setRePassword('');
		setCurPassword('');
		account_controller.change_user_password(newPassword, curPassword)
			.then(response => {
				if (response.ok) {
					alert(t("Update Successful"));
				}
			})
			.catch(error => {
				alert(t("Password Incorrect"));
			})
	}

	const toggle = () => {
		setAccountModal(!accountModal);
	}

	const togglePasswordModal = () => {
		if (passwordValidationError || matchPasswordsError) {
			return;
		}

		setPasswordModal(!passwordModal);
	}

	if (!times) {
		return <></>
	}

	return <section id='account' className={css(SettingsPageStyles.settings_card)} onSubmit={(e) => {
		e.preventDefault();
		return;
	}}>
		<h2 className={css(SettingsPageStyles.card_title)}>{t("Account")}</h2>
		<div className={css(SettingsPageStyles.settings_card_body)}>
			<section className={css(SettingsPageStyles.settings_card_sections_container)}>
				<form className={css(SettingsPageStyles.settings_card_section_container)}>
					<h3 className={css(SettingsPageStyles.card_subtitle)}>{t("Account Information")}</h3>
					<label className={css(SettingsPageStyles.card_input_label)} htmlFor="first_name">{t("First Name")}</label>
					<input className={css(SettingsPageStyles.card_input)} type="text" name="first_name" value={changeUser == null ? '' : changeUser.first_name} onChange={handleFirstNameChange} />

					<label className={css(SettingsPageStyles.card_input_label)} htmlFor="last_name">{t("Last Name")}</label>
					<input className={css(SettingsPageStyles.card_input)} type="text" name="last_name" value={changeUser == null ? '' : changeUser.last_name} onChange={handleLastNameChange} />
					<p className={css(SettingsPageStyles.error)}>{nameValidationError}</p>

					<label className={css(SettingsPageStyles.card_input_label)} htmlFor="email">{t("Email")}</label>
					<input className={css(SettingsPageStyles.card_input)} type="text" name="email" disabled defaultValue={changeUser == null ? '' : changeUser.email} />

					<label className={css(SettingsPageStyles.card_input_label)} htmlFor="phone_number">
						{t("Phone Number")}
						<br />
						{t("+country code without leading zero or spaces")}
						<br />
						{t("(e.g. +447XXXXXXXXX)")}
					</label>
					<PhoneNumberInput
						name="phoneNumber"
						key="phoneNumber"
						phoneNumber={phoneNumber}
						setPhoneValidationError={setPhoneValidationError}
						onValid={(phone_number_string) => {
							if (changeUser == null) { return; }
							setChangeUser({ ...changeUser, phone_number: phone_number_string, change_email_phone: true });
						}}
					/>
					<p className={css(SettingsPageStyles.error)}>{phoneValidationError}</p>
					<button className={isChangeUserValid ? css(button_styles.main_button) : css(button_styles.main_button_disabled)}
						style={{ padding: '0.2rem 1rem', margin: '1rem 0', minWidth: '5rem' }}
						disabled={!isChangeUserValid}
						onClick={() => {
							if (changeUser == null || phoneValidationError || nameValidationError) { return; }
							setAccountModal(true);
						}
						}>{t("Save")}</button>
				</form>
				<Modal isOpen={accountModal} toggle={toggle} className={css(SettingsPageStyles.delete_model_style)}>
					<ModalHeader toggle={toggle} style={{ textAlign: 'center' }}>{t('Confirm Settings')}</ModalHeader>
					<ModalBody>
						<Row>
							<legend style={{ fontSize: '1.2em', color: 'red', textAlign: 'center' }}>{t('Are you sure your new account information is correct?')}</legend>
							<Col xs="6" className="d-flex">
								<Button className={css(SettingsPageStyles.cancel_button_style)} onClick={toggle}>
									{t('Cancel')}
								</Button>
							</Col>
							<Col xs="6" className="d-flex">
								<Button className={css(SettingsPageStyles.confirm_delete_button_style)} onClick={() => {
									toggle();
									if (!changeUser) { return; }
									account_controller.modify_user_account({
										email: changeUser.email,
										phone_number: changeUser.phone_number,
										first_name: changeUser.first_name,
										last_name: changeUser.last_name
									})
								}}>
									{t('Save')}
								</Button>
							</Col>
						</Row>
					</ModalBody>
				</Modal>

				<form className={css(SettingsPageStyles.settings_card_section_container)}>
					<h3 className={css(SettingsPageStyles.card_subtitle)}>{t("Change Password")}</h3>
					<label className={css(SettingsPageStyles.card_input_label)} htmlFor="">{t("Old Password")}</label>
					<input className={css(SettingsPageStyles.card_input)} type="password" onChange={handleCurPasswordChange} value={curPassword} />

					<label className={css(SettingsPageStyles.card_input_label)} htmlFor="">{t("New Password")}</label>
					<input className={css(SettingsPageStyles.card_input)} type="password" placeholder="" value={newPassword} onChange={handleNewPasswordChange} />

					<label className={css(SettingsPageStyles.card_input_label)} htmlFor="">{t("Repeat New Password")}</label>
					<input className={css(SettingsPageStyles.card_input)} type="password" value={rePassword} onChange={handleRepeatPasswordChange} />

					<p className={css(SettingsPageStyles.error)}>{passwordValidationError}</p>
					<p className={css(SettingsPageStyles.error)}>{matchPasswordsError}</p>
					<button className={css(isPasswordInvalid ? button_styles.main_button_disabled : button_styles.main_button)}
						style={{ padding: '0.2rem 1rem', margin: '1rem 0', minWidth: '5rem' }}
						disabled={isPasswordInvalid}
						onClick={() => {
							togglePasswordModal();
						}}>{t("Save")}</button>
					<a className={css(SettingsPageStyles.link)} href="https://device-service.manhole-metrics.com/redirect-to-password-policy">({t('View our Password Policy')})</a>
				</form>
				<Modal isOpen={passwordModal} toggle={togglePasswordModal} className={css(SettingsPageStyles.delete_model_style)}>
					<ModalHeader toggle={togglePasswordModal} style={{ textAlign: 'center' }}>{t('Confirm Settings')}</ModalHeader>
					<ModalBody>
						<Row>
							<legend style={{ fontSize: '1.2em', color: 'red', textAlign: 'center' }}>{t('Are you sure you want to change your password?')}</legend>
							<Col xs="6" className="d-flex">
								<Button className={css(SettingsPageStyles.cancel_button_style)} onClick={togglePasswordModal}>
									{t('Cancel')}
								</Button>
							</Col>
							<Col xs="6" className="d-flex">
								<Button className={css(SettingsPageStyles.confirm_delete_button_style)} onClick={() => {
									togglePasswordModal();
									handleReset();
								}}>
									{t('Save')}
								</Button>
							</Col>
						</Row>
					</ModalBody>
				</Modal>
			</section>

		</div>
	</section>

});
