import { css } from "aphrodite";
import { Button, ButtonProps } from "reactstrap";
import { appStyles } from "../../styles/app_styles";

type Props = ButtonProps & {
    isActive: boolean;
    onClick: (e: MouseEvent) => void;
};

export function NavButton({ isActive, onClick, children }: Props) {
    const classes = css(appStyles.nav_button_styles, isActive && appStyles.current_page);
    return <Button variant="outlined" className={classes} onClick={onClick}>{children}</Button>
}