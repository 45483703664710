import styled from "@emotion/styled";
import Link, { LinkProps } from "@mui/material/Link/Link";
import { ReactNode } from "react";

const StyledLink = styled(Link)`
	color: rgb(0, 0, 153) !important;
	font-weight: 600;
	font-family: 'Ubuntu', sans-serif;
	text-decoration: underline !important;
`;

export const MHMHyperlink: React.FC<LinkProps & {children?: ReactNode}> = (props: LinkProps & {children?: ReactNode}) => {
	return (
		<StyledLink {...props}>
			{props.children}
		</StyledLink>
	);
}