import React, { ChangeEvent, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContentText, DialogActions, Typography, ThemeProvider } from '@mui/material';
import { account_controller } from '../../controllers/account_controller';
import { useRecoilState } from 'recoil';
import { firstTimeLoginAtom } from '../../models/atoms/account_atoms';
import { useTranslation } from '../../contexts/TranslationContext';
import { MHMDialogContent } from './DialogContent';
import { MHMTextField } from './TextField';
import { MHMLabelledCheckbox } from './Checkbox';
import { MHMTypography } from './Typography';
import { MHMHyperlink } from './Hyperlink';
import { validatePassword, validateNewOldPasswordsMatch, validateRepeatPasswordsMatch } from '../../utils/account_validations';
import { repeat } from 'cypress/types/lodash';
import styled from '@emotion/styled';
import { auth_controller } from '../../controllers/auth_controller';
import { theme } from './Theme';

const PolicyLinks: React.FC = () => {
	const { t } = useTranslation();
	return (
		<MHMTypography>
			{t('agree_to_tou_prefix')}&nbsp;
			<MHMHyperlink href="https://termsofuse.manholemetrics.com" target="_blank">{t('Terms Of Use')}</MHMHyperlink>
			&nbsp;{t('agree_to_tou_and')}&nbsp;
			<MHMHyperlink href="https://privacyandcookies.manholemetrics.com" target="_blank">{t('Privacy Policy')}</MHMHyperlink>
			&nbsp;{t('agree_to_tou_postfix')}
		</MHMTypography>
	);
}

const FirstLoginModal: React.FC = () => {
	const [firstTimeLogin, setFirstTimeLogin] = useRecoilState(firstTimeLoginAtom);
	const [error, setError] = useState('');
	const { t } = useTranslation();
	const [readManual, setReadManual] = useState(false);
	const [showReadManualMessage, setShowReadManualMessage] = useState(false);
	const [oldPassword, setOldPassword] = useState('');
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	console.log('firstTimeLogin', firstTimeLogin);

	const isButtonDisabled = Boolean(error || !oldPassword || !password || !repeatPassword) || !readManual;

	function validatePasswords(newOldPassword: string, newPassword: string, newRepeatPassword: string) {
		const passwordError = validatePassword(newPassword);
		if (passwordError) {
			setError(t(passwordError));
			return;
		}

		const newOldPasswordError = validateNewOldPasswordsMatch(newPassword, newOldPassword);
		if (newOldPasswordError) {
			setError(t(newOldPasswordError));
			return;
		}

		const repeatPasswordError = validateRepeatPasswordsMatch(newPassword, newRepeatPassword);
		if (repeatPasswordError) {
			setError(t(repeatPasswordError));
			return;
		}

		setError('');
	}

	function handleOldPasswordChange(e: ChangeEvent<HTMLInputElement>) {
		const newOldPassword = e.target.value;
		setOldPassword(newOldPassword);
		validatePasswords(newOldPassword, password, repeatPassword);
	}

	function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
		const newPassword = e.target.value;
		setPassword(newPassword);
		validatePasswords(oldPassword, newPassword, repeatPassword);
	}

	function handleRepeatPasswordChange(e: ChangeEvent<HTMLInputElement>) {
		const newRepeatPassword = e.target.value;
		setRepeatPassword(newRepeatPassword);
		validatePasswords(oldPassword, password, newRepeatPassword);
	}

	function toggleReadManual({ target: { checked } }: ChangeEvent<HTMLInputElement>) {
		setReadManual(checked);
		setShowReadManualMessage(!checked);
	}

	function checkReadManual() {
		if (readManual) {
			return;
		}

		setShowReadManualMessage(true);
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={firstTimeLogin}
				onClose={() => { }}
				PaperProps={{
					component: 'form',
					onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();
						const formData = new FormData(event.currentTarget);
						const formJson = Object.fromEntries((formData as any).entries());
						const oldPassword = formJson.oldPassword;
						account_controller.change_user_password(password, oldPassword).then(() => {
							setFirstTimeLogin(false);
							localStorage.setItem('firstTimeLogin', 'false');
						}).catch(e => {
							console.error('Validation failed:', e);
							if (e.detail === 'Account Not Found') {
								auth_controller.logout();
							} else if (e.detail === 'Incorrect Password') {
								setError(t('Incorrect Password: Check Password in Email'));
							} else {
								setError(e.detail);
							}
						});
					}
				}}
			>
				<DialogTitle>{t("Change Password")}</DialogTitle>
				<MHMDialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
					<MHMTextField autoFocus required margin="dense" id="oldPassword" name="oldPassword" label={t("Old Password")} type="password" value={oldPassword} onChange={handleOldPasswordChange} />
					<MHMTextField required margin="dense" id="newPassword" name="newPassword" label={t("New Password")} type="password" value={password} onChange={handlePasswordChange} />
					<MHMTextField required margin="dense" id="confirmPassword" name="confirmPassword" label={t("Repeat New Password")} type="password" value={repeatPassword} onChange={handleRepeatPasswordChange} />
					<MHMHyperlink href="https://device-service.manhole-metrics.com/redirect-to-password-policy" target="_blank">{t('View our Password Policy')}</MHMHyperlink>
					{/* minWidth + maxWidth trick to prevent error text from expanding its parents horizontally. Source: https://stackoverflow.com/a/73654975 */}
					<DialogContentText style={{ color: 'red', minWidth: '100%', maxWidth: 0 }}>{error}</DialogContentText>
					<MHMLabelledCheckbox checkboxProps={{ checked: readManual, onChange: toggleReadManual }} label={<PolicyLinks />} />
					{showReadManualMessage
						? <DialogContentText style={{ color: 'red', minWidth: '100%', maxWidth: 0 }}>{t('You must agree to our Terms of Use and Privacy Policy!')}</DialogContentText>
						: null}
				</MHMDialogContent>
				<DialogActions >
					{/* put onClick handler here to handle click events when button is disabled */}
					<div onClick={checkReadManual}>
						<Button type="submit" color="primary" variant="contained" sx={{ fontFamily: 'Ubuntu, sans-serif', color: 'white' }} disabled={isButtonDisabled}>{t("Confirm")}</Button>
					</div>
				</DialogActions>
			</Dialog >
		</ThemeProvider>
	);
};

export default FirstLoginModal;
