/**
 * @returns error message if password is invalid, void otherwise
 * 
 * Error message needs to be translated
 */
export function validatePassword(newPassword: string) {
    if (newPassword.length < 12 || !(/\d/).test(newPassword) || !(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/).test(newPassword)) {
        return 'Password must be at least 12 characters and contain at least 1 number and 1 special character!'
    }
}

/**
 * @returns error message if newPassword doesn't matches newRepeatPassword, void otherwise
 * 
 * Error message needs to be translated
 */
export function validateRepeatPasswordsMatch(newPassword: string, newRepeatPassword: string) {
    if (newPassword !== newRepeatPassword) {
        return "Passwords don't match!";
    }
}

/**
 * @returns error message if newPassword matches oldPassword, void otherwise
 * 
 * Error message needs to be translated
 */
export function validateNewOldPasswordsMatch(newPassword: string, oldPassword: string) {
    if (newPassword === oldPassword) {
        return 'New password cannot be the same as the old password!'
    }
}

/**
 * @returns error message if either names are invalid, void otherwise
 * 
 * Error message needs to be translated
 */
export function validateNames(firstName: string, lastName: string) {
    if (!firstName|| !lastName || firstName.length < 2 || lastName.length < 2) {
        return 'First and Last names cannot be empty and must be at least 2 characters long!';
    }

    if (!/^[a-zA-Z- ]+$/.test(firstName) || !/^[a-zA-Z- ]+$/.test(lastName)) {
        return 'Ensure that both the First and Last names exclusively consist of valid English alphabet characters!';
    }
}