import styled from "@emotion/styled";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import { useTranslation } from "../../contexts/TranslationContext";
import { Button, DialogActions, ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { PickersActionBarProps, usePickersTranslations } from "@mui/x-date-pickers";

const StyledDatePicker = styled(DateTimePicker)`
	.Mui-error {
		border-color: #e0e0e0;
	}
`;

type DatePickerProps = {
	value: Date
	onChange: (date: Date) => void
	shouldDisableDate?: (date: Date) => boolean
	error?: boolean
}

/**
 * MUI x-date-picker doesn't have a way to change ok button variant, so have to create own ActionBar component to override it
 * @see https://mui.com/x/react-date-pickers/custom-components/#component
 */
function CustomActionBar(props: PickersActionBarProps) {
	const { onAccept, actions, className } = props;
	const translations = usePickersTranslations();

	if (!actions || actions.length === 0) {
		return null;
	}

	return (
		<DialogActions className={className}>
			<Button variant="contained" onClick={onAccept} disableElevation>
				{translations.okButtonLabel}
			</Button>
		</DialogActions>
	);
}

export const MHMDatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
	const { language } = useTranslation();
	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language.toLowerCase()}>
				<StyledDatePicker
					value={dayjs(props.value)}
					onChange={(date: Dayjs | null) => {
						if (!date) {
							return;
						}
						props.onChange(date.toDate());
					}}
					shouldDisableDate={(date: Dayjs) => {
						return props.shouldDisableDate ? props.shouldDisableDate(date.toDate()) : false;
					}}
					shouldDisableTime={(date: Dayjs) => {
						return props.shouldDisableDate ? props.shouldDisableDate(date.toDate()) : false;
					}}
					slotProps={{ textField: { error: props.error } }}
					slots={{ actionBar: CustomActionBar }}
				/>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
