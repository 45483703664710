import React from 'react';
import { memo, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import '../../styles/shared_styles.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import { changeCompanyDeviceAtom, changeROCAtom, companyROCSettingsAtom } from "../../../models/atoms/company_atoms";
import { company_controller } from "../../../controllers/company_controller";
import { toHour, toMinute } from "../../../utils/helper_functions";
import { SettingsPageStyles } from "../../styles/settings_page_styles";
import { button_styles } from "../../styles/reusable_styles";
import '../../styles/shared_styles.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the translation context
/* eslint-disable no-useless-escape */

export const DeviceSettingsComponent = memo(
	(_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
		const { t, distance } = useTranslation(); // Use the translation hook
		const [times, setTimes] = useRecoilState(changeCompanyDeviceAtom);
		const [rocSettings, setROCSettings] = useRecoilState(changeROCAtom);
		const roc_settings = useRecoilValue(companyROCSettingsAtom);

		const [rocSettingsError, setROCSettingsError] = useState('');
		const [normalRelayError, setNormalRelayError] = useState('');
		const [normalReadingError, setNormalReadingError] = useState('');
		const [warningRelayError, setWarningRelayError] = useState('');
		const [warningReadingError, setWarningReadingError] = useState('');
		const [totalMeasurementsWarning, setTotalMeasurementsWarning] = useState('');

		const [modal, setModal] = useState(false);
		const toggle = () => setModal(!modal);

		// Check frequencies against the READING_THRESHOLD
		useEffect(() => {
			if (!times) return;
			// relayFreq = wait_time / 3600
			// readingFreq = sleep_time / 60
			// total measurements = relayFreq * (60 / readingFreq)
			// total measurements = (wait_time / 3600) * (60 / (sleep_time / 60))
			// total measurements = (wait_time / 3600) * (3600 / sleep_time)
			// total measurements = wait_time / sleep_time
			const totalNormalMeasurements = times.message_wait_time_s / times.measurement_sleep_time_s
			const totalWarningMeasurements = times.warning_message_wait_time_s / times.warning_measurement_sleep_time_s;

			if (totalNormalMeasurements > 100 || totalWarningMeasurements > 100) {
				setTotalMeasurementsWarning(t('Total measurements exceed 100! Devices are restricted to 100 measurents per transmission interval.'));
			} else {
				setTotalMeasurementsWarning('');
			}
			if (times.message_wait_time_s < 720 || times.message_wait_time_s > 72 * 60 * 60) {
				setNormalRelayError(t('Not in the allowable range of 0.2-72 hours'));
			} else {
				setNormalRelayError('');
			}
			const maxReadingFrequency = Math.min(times.message_wait_time_s / 60, 720); // Dynamic upper value
			if (times.measurement_sleep_time_s < 300 || times.measurement_sleep_time_s > maxReadingFrequency * 60) {
				setNormalReadingError(t('Not in the allowable range minutes', { maxReadingFrequency: maxReadingFrequency.toFixed(1) }));
			} else {
				setNormalReadingError('');
			}
			if (times.warning_message_wait_time_s < 360 || times.warning_message_wait_time_s > times.message_wait_time_s) {
				setWarningRelayError(t('Not in the allowable range hours', { hours: (times.message_wait_time_s / 3600).toFixed(1) }));
			} else {
				setWarningRelayError('');
			}
			const maxWarningReadingFrequency = Math.min(times.measurement_sleep_time_s / 60, 60); // Dynamic upper value
			if (times.warning_measurement_sleep_time_s < 1 || times.warning_measurement_sleep_time_s > maxWarningReadingFrequency * 60) {
				setWarningReadingError(t('Not in the allowable range minutes', { maxReadingFrequency: maxWarningReadingFrequency.toFixed(1) }));
			} else {
				setWarningReadingError('');
			}
		}, [times, t]);

		if (!times || !rocSettings) {
			return <></>;
		}

		const isSaveButtonEnabled =
			(times?.change_time || rocSettings?.change_roc) &&
			!rocSettingsError &&
			!normalRelayError &&
			!normalReadingError &&
			!warningRelayError &&
			!warningReadingError &&
			!totalMeasurementsWarning;

		return (
			<form
				id='frequencies'
				className={css(SettingsPageStyles.settings_card)}
				onSubmit={(e) => {
					e.preventDefault();
					if (times === null) { return; }
					if (times.change_time) {
						company_controller.modify_device_settings(
							times?.message_wait_time_s,
							times?.measurement_sleep_time_s,
							times?.warning_message_wait_time_s,
							times?.warning_measurement_sleep_time_s
						);
					}
					if (rocSettings.change_roc) {
						company_controller.modify_company_roc_settings(
							rocSettings.roc_mode_on,
							rocSettings.roc_threshold
						);
					}
				}}
			>
				<h2 className={css(SettingsPageStyles.card_title)}>{t('Frequencies')}</h2>

				<div className={css(SettingsPageStyles.settings_card_body)}>
					<section className={css(SettingsPageStyles.settings_card_sections_container)}>
						<div className={css(SettingsPageStyles.settings_card_section_container)}>
							<h3 className={css(SettingsPageStyles.card_subtitle)}>
								{t('Normal Operation Mode')}
							</h3>

							<label className={css(SettingsPageStyles.card_input_label)} htmlFor="">
								{t('Relay Frequency:')}
							</label>
							<div>
								<input
									className={css(SettingsPageStyles.card_input)}
									type="text"
									defaultValue={toHour(times?.message_wait_time_s)}
									onChange={(e) => {
										if (times === null) { return; }
										if (!(/^[0-9.,]+$/).test(e.target.value)) {
											setNormalRelayError(t('Please provide a valid number!'));
											return;
										}
										setTimes({
											...times,
											message_wait_time_s: Number(e.target.value) * 3600,
											change_time: true
										});
									}}
								/>
								<span>{t('Hours')}</span>
							</div>
							<p className={css(SettingsPageStyles.error)}>{normalRelayError}</p>

							<label className={css(SettingsPageStyles.card_input_label)} htmlFor="">
								{t('Sensor Reading Frequency:')}
							</label>
							<div>
								<input
									className={css(SettingsPageStyles.card_input)}
									type="text"
									defaultValue={toMinute(times?.measurement_sleep_time_s)}
									onChange={(e) => {
										if (times === null) { return; }

										if (!(/^[0-9.,]+$/).test(e.target.value)) {
											setNormalReadingError(t('Please provide a valid number!'));
											return;
										}
										setTimes({
											...times,
											measurement_sleep_time_s: Number(e.target.value) * 60,
											change_time: true
										});
									}}
								/>
								<span>{t('Minutes')}</span>
							</div>
							<p className={css(SettingsPageStyles.error)}>{normalReadingError}</p>
						</div>

						<div className={css(SettingsPageStyles.settings_card_section_container)}>
							<h3 className={css(SettingsPageStyles.card_subtitle)}>
								{t('High Warning Mode')}
							</h3>

							<label className={css(SettingsPageStyles.card_input_label)} htmlFor="">
								{t('Relay Frequency:')}
							</label>
							<div>
								<input
									className={css(SettingsPageStyles.card_input)}
									type="text"
									defaultValue={toHour(times?.warning_message_wait_time_s)}
									onChange={(e) => {
										if (times === null) { return; }
										if (!(/^[0-9.,]+$/).test(e.target.value)) {
											setWarningRelayError(t('Please provide a valid number!'));
											return;
										}
										setTimes({
											...times,
											warning_message_wait_time_s: Number(e.target.value) * 3600,
											change_time: true
										});
									}}
								/>
								<span>{t('Hour')}</span>
							</div>
							<p className={css(SettingsPageStyles.error)}>{warningRelayError}</p>

							<label className={css(SettingsPageStyles.card_input_label)} htmlFor="">
								{t('Sensor Reading Frequency:')}
							</label>
							<div>
								<input
									className={css(SettingsPageStyles.card_input)}
									type="text"
									defaultValue={toMinute(times?.warning_measurement_sleep_time_s)}
									onChange={(e) => {
										if (times === null) { return; }
										if (!(/^[0-9.,]+$/).test(e.target.value)) {
											setWarningReadingError(t('Please provide a valid number!'));
											return;
										}
										setTimes({
											...times,
											warning_measurement_sleep_time_s: Number(e.target.value) * 60,
											change_time: true
										});
									}}
								/>
								<span>{t('Minutes')}</span>
							</div>
							<p className={css(SettingsPageStyles.error)}>{warningReadingError}</p>
						</div>
					</section>

					<p className={css(SettingsPageStyles.error)}>{totalMeasurementsWarning}</p>

					<p className={css(SettingsPageStyles.disclaimer)}>
						{t(
							'The device compares every reading to the defined Warning Threshold (see device pop-outs)... High Warning Mode overrides both Low Power and Weather Mode (if engaged).'
						)}
					</p>

					<div style={{ display: "flex" }}>
						<label className="switch" style={{ marginRight: '0.5rem' }}>
							<input
								type="checkbox"
								onChange={(e) => {
									if (!rocSettings) return;
									setROCSettings({ ...rocSettings, roc_mode_on: e.target.checked, change_roc: true });
								}}
								checked={rocSettings.change_roc ? rocSettings.roc_mode_on : roc_settings?.roc_mode_on}
							/>
							<span className="slider round"></span>
						</label>
						<p style={{ fontWeight: '500' }}>{t('Rate of Change (ROC)')}</p>
					</div>

					<p className={css(SettingsPageStyles.disclaimer)}>
						{t(
							'The device calculates the rate of change (ROC)... High Warning Mode overrides Weather Mode (if engaged).'
						)}
					</p>

					{rocSettings.roc_mode_on && (
						<div>
							<label
								className={css(SettingsPageStyles.card_input_label)}
								style={{ marginRight: '0.7rem' }}
								htmlFor=""
							>
								{t('ROC Threshold:')}
							</label>
							<input
								className={css(SettingsPageStyles.card_input)}
								type="text"
								defaultValue={distance.from_metric(rocSettings.roc_threshold).toFixed(1)}
								onChange={(e) => {
									setROCSettingsError('');
									const value = distance.from(e.target.value);
									if (!(/^[0-9.,]+$/).test(e.target.value)) {
										setROCSettingsError(t('Please provide a valid number!'));
									}
									else if (value < 50 || value > 250) {
										setROCSettingsError(t('Not in the allowable range of 50-250 millimeters per hour!'));
									}
									else {
										setROCSettings({
											...rocSettings,
											roc_threshold: value,
											change_roc: true
										});
									}
								}}
							/>
							<span>{t('mm/hour')}</span>
						</div>
					)}
					<p className={css(SettingsPageStyles.error)}>{rocSettingsError}</p>

					<p className={css(SettingsPageStyles.disclaimer)}>
						{t(
							"Please note that device settings are applied to all of the devices in the company's fleet..."
						)}
					</p>

					<button
						className={css(isSaveButtonEnabled ? button_styles.main_button : button_styles.main_button_disabled)}
						style={{
							padding: '0.2rem 1rem',
							margin: '1rem 0',
							minWidth: '5rem',
							cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed'
						}}
						onClick={toggle}
						disabled={!isSaveButtonEnabled}
					>
						{t('Save')}
					</button>

					<Modal
						isOpen={modal}
						toggle={toggle}
						className={css(SettingsPageStyles.delete_model_style)}
					>
						<ModalHeader toggle={toggle} style={{ textAlign: 'center' }}>
							{t('Confirm Settings')}
						</ModalHeader>
						<ModalBody>
							<Row>
								<legend style={{ fontSize: '1.2em', color: 'red', textAlign: 'center' }}>
									{t('Are you sure you want to apply these new settings to all devices in the fleet?')}
								</legend>
								<Col xs="6" className="d-flex">
									<Button className={css(SettingsPageStyles.cancel_button_style)} onClick={toggle}>
										{t('Cancel')}
									</Button>
								</Col>
								<Col xs="6" className="d-flex">
									<Button
										className={css(SettingsPageStyles.confirm_delete_button_style)}
										onClick={() => {
											toggle();
											if (times === null) { return; }
											if (times.change_time) {
												company_controller.modify_device_settings(
													times?.message_wait_time_s,
													times?.measurement_sleep_time_s,
													times?.warning_message_wait_time_s,
													times?.warning_measurement_sleep_time_s
												);
											}
											if (rocSettings.change_roc) {
												company_controller.modify_company_roc_settings(
													rocSettings.roc_mode_on,
													rocSettings.roc_threshold
												);
											}
										}}
									>
										{t('Save')}
									</Button>
								</Col>
							</Row>
						</ModalBody>
					</Modal>
				</div>
			</form>
		);
	}
);
