import { accountCompanyInfoAtom, accountListStateAtom, selectedAccountStateAtom, userAccountAtom } from "../models/atoms/account_atoms";
import { authStateAtom } from "../models/atoms/auth_atoms";
import { getRecoil, setRecoil } from "recoil-nexus";
import { accountInterface, accountListInterface, createAccountInterface, accountCompanyInfoInterface } from '../models/interfaces/account_interfaces';
import { languageAtom } from "../models/atoms/ui_atoms";


const auth_link = process.env.REACT_APP_AUTH_API_URL;


export const account_controller = {

	get_account_list: function() {
		var obj = {
			link: auth_link + '/accounts/accounts',
			object: {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
				}
			}
		};
		fetch(obj.link, obj.object)
			.then(async response => await response.json() as accountListInterface)
			.then(accountList => {
				console.log(accountList);
				setRecoil(accountListStateAtom, accountList);
			}).catch((e) => {
				console.error(e);
				setRecoil(accountListStateAtom, { accounts: [] });
			});
	},

	create_account: async function(email: string, password: string,
		view_devices: boolean,
		register_devices: boolean,
		manage_devices: boolean,
		manage_accounts: boolean,
		view_device_data: boolean,
		test_signal: boolean,
		first_name: string,
		last_name: string,
		phone_number: string) {

		const new_account: createAccountInterface = {
			email: email,
			first_name: first_name,
			last_name: last_name,
			phone_number: phone_number,
			password: password,
			permissions: {
				view_devices: view_devices,
				register_devices: register_devices,
				manage_devices: manage_devices,
				manage_accounts: manage_accounts,
				view_device_data: view_device_data,
				test_signal: test_signal
			}
		}
		var obj = {
			link: auth_link + '/accounts/account',
			object: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
					'Content-type': 'application/json'
				},
				body: JSON.stringify(
					new_account
				)
			}
		};

		try {
			const res = await fetch(obj.link, obj.object);
			if (!res.ok) {
				const errorMessage = await res.json();
				throw new Error('Failed to create account', { cause: errorMessage.field });
			}
			this.get_account_list();
		} catch (e) {
			console.error(e);
			throw new Error('Failed to create account');
		}

	},

	select_account: function(account_id: string, callback = () => { }) {
		var obj = {
			link: auth_link + '/accounts/account/' + account_id,
			object: {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
				}
			}
		};
		fetch(obj.link, obj.object)
			.then(async response => await response.json() as accountInterface)
			.then(account => {
				setRecoil(selectedAccountStateAtom, account);
				return account;
			})
			.then(() => callback());
	},

	delete_account: function(account_id: string) {
		var obj = {
			link: auth_link + '/accounts/account/' + account_id,
			object: {
				method: 'DELETE',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
				}
			}
		};
		fetch(obj.link, obj.object).then(() => this.get_account_list());
	},

	modify_account: function(
		account_id: string,
		view_devices: boolean,
		register_devices: boolean,
		manage_devices: boolean,
		manage_accounts: boolean,
		view_device_data: boolean,
		test_signal: boolean
	) {
		const new_account = {
			id: account_id,
			permissions: {
				view_devices: view_devices,
				register_devices: register_devices,
				manage_devices: manage_devices,
				manage_accounts: manage_accounts,
				view_device_data: view_device_data,
				test_signal: test_signal
			}
		}
		var obj = {
			link: auth_link + '/accounts/account',
			object: {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
					'Content-type': 'application/json'
				},
				body: JSON.stringify(
					new_account
				)
			}
		};
		fetch(obj.link, obj.object).then(() => { this.get_account_list() });
	},

	get_user_account: function(callback = () => { }) {
		var obj = {
			link: auth_link + '/accounts/user_account',
			object: {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
				}
			}
		};
		fetch(obj.link, obj.object)
			.then(async response => await response.json() as accountInterface)
			.then(account => {
				setRecoil(languageAtom, account.language ?? 'en');
				setRecoil(userAccountAtom, account);
			})
			.then(() => callback())
			.catch(e => {
				console.error(e);
				this.get_account_list();
			});
	},

	modify_user_account: function(
		user_patch: { email?: string, phone_number?: string, text_alerts?: boolean, email_alerts?: boolean, first_name?: string, last_name?: string }) {
		var obj = {
			link: auth_link + '/accounts/user_account',
			object: {
				method: 'PATCH',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
					'Content-type': 'application/json'
				},
				body: JSON.stringify(
					user_patch
				)
			}
		};
		console.log("user_patch", user_patch);
		fetch(obj.link, obj.object).then(() => {
			console.log("getting user account after patch");
			this.get_user_account();
			this.get_account_list();
		});
	},

	change_account_password: function(account_id: string, new_password: string) {
		var obj = {
			link: auth_link + '/accounts/password?' + new URLSearchParams({
				account_id: account_id,
				new_password: new_password,
			}),
			object: {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
				}
			}
		};
		fetch(obj.link, obj.object)
	},

	change_user_password: async function(new_password: string, current_password: string) {
		var obj = {
			link: auth_link + '/accounts/user_password?' + new URLSearchParams({
				current_password: current_password,
				new_password: new_password,
			}),
			object: {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
				}
			}
		};
		const response = await fetch(obj.link, obj.object);
		if (response.ok) {
			return response;
		} else {
			throw await response.json();
		}
	},

	get_company_name: function() {
		var obj = {
			link: auth_link + '/account/company_name',
			object: {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + getRecoil(authStateAtom).token,
				}
			}
		};
		fetch(obj.link, obj.object)
			.then(async response => await response.json() as accountCompanyInfoInterface)
			.then(company_info => setRecoil(accountCompanyInfoAtom, company_info));
	},

	send_password_link: function(email: string, callback: () => void) {
		var obj = {
			link: auth_link + '/accounts/password_link?' + new URLSearchParams({
				email: email,
			}),
			object: {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
				}
			}
		};
		fetch(obj.link, obj.object).then(response => {
			if (response.status === 200) {
				callback();
			}
		})

	},
}

