import styled from "@emotion/styled";
import Typography, { TypographyProps } from "@mui/material/Typography/Typography";
import { ReactNode } from "react";

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: 'black',
	fontFamily: 'Ubuntu, sans-serif',
}));

const ErrorTypography = styled(StyledTypography)(({ theme }) => ({
	color: 'red',
}));

const TitleTypography = styled(StyledTypography)(({ theme }) => ({
	color: '#000099',
	fontWeight: 600,
}));
//`
//	.MuiTypography-root {
//		color: red;
//		font-weight: 600;
//		font-family: 'Ubuntu', sans-serif;
//	}
//`;

export const MHMTypography: React.FC<TypographyProps & { children?: ReactNode }> = (props: TypographyProps) => {
	return (
		<StyledTypography {...props}>
			{props.children}
		</StyledTypography>
	);
}

export const ErrorText: React.FC<TypographyProps & { children?: ReactNode }> = (props: TypographyProps) => {
	return (
		<ErrorTypography {...props}>
			{props.children}
		</ErrorTypography>
	);
}

export const TitleText: React.FC<TypographyProps & { children?: ReactNode }> = (props: TypographyProps) => {
	return (
		<TitleTypography {...props}>
			{props.children}
		</TitleTypography>
	);
}
