import React, { ChangeEvent } from 'react';
import { memo, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import { newAccountModalAtom, deleteModalAtom, modifyAccountModalAtom, selectedAccountStateAtom } from "../../../models/atoms/account_atoms";
import { account_controller } from "../../../controllers/account_controller";
import { css } from 'aphrodite';
import '../../../App.css';
import {
    Button,
    CardText,
    Row,
    Col,
    FormGroup, Label,
    Modal, ModalHeader, ModalBody, Input, Form,
} from "reactstrap";
/* eslint-disable no-useless-escape */
import { accountsPageStyle } from "../../styles/accounts_page_styles";
import { useTranslation } from '../../../contexts/TranslationContext';
import { ModalPhoneNumberInput, PhoneNumberInput } from '../settings/PhoneNumberInput';
import { validateNames, validatePassword, validateRepeatPasswordsMatch } from '../../../utils/account_validations';

export const NewAccountModalComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const [newAccountModal, setNewAccountModal] = useRecoilState(newAccountModalAtom);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nameError, setNameError] = useState('');
    const [passwordValidationError, setPasswordValidationError] = useState('');
    const [matchPasswordsError, setMatchPasswordsError] = useState(''); 
    const [email, setEmail] = useState('');
    const [emailValidationError, setEmailValidationError] = useState("");
    const [phoneValidationError, setPhoneValidationError] = useState("");
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
	const [newPhoneNumber, setNewPhoneNumber] = useState('-');
    const [viewPermission, setViewPermission] = useState(false);
    const [installPermission, setInstallPermission] = useState(false);
    const [adminPermission, setAdminPermission] = useState(false);

    const hasErrors = Boolean(nameError || emailValidationError || phoneValidationError || passwordValidationError);
    const isEmpty = Boolean(!firstName || !lastName || !email || !password || (!viewPermission && !installPermission && !adminPermission));

    function handleFirstNameChange(e: ChangeEvent<HTMLInputElement>) {
        const newFirstName = e.target.value;
        setFirstName(newFirstName);
        setNameError(t(validateNames(newFirstName, lastName) ?? ''));
    }

    function handleLastNameChange(e: ChangeEvent<HTMLInputElement>) {
        const newLastName = e.target.value;
        setLastName(newLastName);
        setNameError(t(validateNames(firstName, newLastName) ?? ''));
    }

    function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordValidationError(validatePassword(newPassword) ?? '');
        setMatchPasswordsError(t(validateRepeatPasswordsMatch(newPassword, repeatPassword) ?? ''))
    }

    function handleRepeatPasswordChange(e: ChangeEvent<HTMLInputElement>) {
        const newRepeatPassword = e.target.value;
        setRepeatPassword(newRepeatPassword);
        setMatchPasswordsError(t(validateRepeatPasswordsMatch(password, newRepeatPassword) ?? ''))
    }

    function validateEmail(e: ChangeEvent<HTMLInputElement>) {
        const newEmail = e.target.value;
        setEmail(newEmail);

        if (!newEmail || newEmail.length < 5 || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(newEmail)) {
            setEmailValidationError(t("Please provide a valid email address!"));
            return;
        }
    
        setEmailValidationError("");
    }

    function toggleViewPermission() {
        setViewPermission(v => !v);
    }

    function toggleInstallPermission() {
        setInstallPermission(i => !i);
    }

    function toggleAdminPermission() {
        setAdminPermission(a => !a);
    }

    const onFormSubmitAccount: React.FormEventHandler<HTMLFormElement> = async (e : React.FormEvent) => {
        e.preventDefault();

        if (hasErrors || isEmpty) {
            return;
        }

        try {
            await account_controller.create_account(
                email.toLowerCase(),
                password,
                viewPermission,
                installPermission,
                adminPermission,
                adminPermission,
                viewPermission,
                installPermission,
                firstName,
                lastName,
                newPhoneNumber,
            )
            setNewAccountModal(false);
        } catch (e) {
            if (!(e instanceof Error)) {
                throw e;
            }
            // TODO: refactor to better error handling
            switch (e.cause) {
                case 'email':
                    setEmailValidationError('This email address is already in use');
                    break;
                default:
                    throw e;
            }
        }

    }

    return <Modal isOpen={true}>
    <ModalHeader toggle={() => {
       setNewAccountModal(!newAccountModal);
       setNameError("");
       setPhoneValidationError("");
       setEmailValidationError("");
       setPasswordValidationError("");
       }}>
        {t('Create New Account')}
    </ModalHeader>
    <ModalBody>
        <Form onSubmit={(e) => onFormSubmitAccount(e)}>
            <legend style={{fontSize: '1.2em'}}>{t('Details')}</legend>
            <FormGroup valid>
                <Label for="newFirstName">{t('First Name')}*</Label>
                <Input name="firstName" id="newFirstName" placeholder="" value={firstName} onChange={handleFirstNameChange}></Input>
                <p></p>
                <Label for="newLastName">{t('Last Name')}*</Label>
                <Input name="lastName" id="newLastName" placeholder="" value={lastName} onChange={handleLastNameChange}></Input>
                <p className="password-alert">
                {nameError}
                </p>
                <Label for="newEmail">{t('Email')}*</Label>
                <Input type="email" name="email" id="newEmail" placeholder="" value={email} onChange={validateEmail}></Input>
                <p className="password-alert">
                {emailValidationError}
                </p>
                <Label for="newPhoneNumber">{t('Phone Number (+country code without leading zero or spaces)')}</Label>
				<ModalPhoneNumberInput key="newPhoneNumber" name="newPhoneNumber" phoneNumber="" setPhoneValidationError={setPhoneValidationError} onValid={(phone_number) => {setNewPhoneNumber(phone_number)}} />
                <p className="password-alert">
                {phoneValidationError}
                </p>
                <Label for="newPassword">
                        {t('Password')}* <a className={css(accountsPageStyle.link)} href="https://device-service.manhole-metrics.com/redirect-to-password-policy">({t('View our Password Policy')})</a>
                </Label>
                <Input type="password" name="password" id="newPassword" value={password}
                    placeholder=""  onChange={handlePasswordChange}></Input>
                <p></p>
                <Label for="repeatNewPassword" style={{marginTop: 5}}>{t('Repeat Password')}*</Label>
                <Input type="password" name="confirm_password" id="repeatNewPassword" value={repeatPassword}
                    placeholder=""  onChange={handleRepeatPasswordChange}></Input>
                <p className="password-alert">
                {passwordValidationError}
                </p>
                <p className="password-alert">
                {matchPasswordsError}
                </p>

            </FormGroup>
            <legend style={{fontSize: '1.2em'}}>{t('Permissions')}</legend>
            <FormGroup check>
                <Label check>
                    <Input id="newView" type="checkbox" checked={viewPermission} onChange={toggleViewPermission} />
                    {t('View')}
                    &nbsp;-&nbsp;
                    {t("can view device data, make notes and add labels, can't view accounts or modify device settings")}
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input id="newInstall" type="checkbox" checked={installPermission} onChange={toggleInstallPermission} />
                    {t('Install')}
                    &nbsp;-&nbsp;
                    {t('all permissions associated with View, additionally able to setup devices via mobile app')}
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input id="newAdmin" type="checkbox" checked={adminPermission} onChange={toggleAdminPermission} />
                    {t('Admin')}
                    &nbsp;-&nbsp;
                    {t('all permissions associated with Install, additionally able to view, create and manage accounts, as well as adjust device settings')}
                </Label>
            </FormGroup>
            <br></br>
            <Button style={{fontWeight: 500}} type="submit" disabled={hasErrors || isEmpty}>{t('Save')}</Button>
        </Form>
    </ModalBody>
</Modal>
})

export const ModifyAccountModalComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const selectedAccount = useRecoilValue(selectedAccountStateAtom)
    const [modifyPassword, setModifyPassword] = useState("");
    const [modifyAccountModal, setModifyAccountModal] = useRecoilState(modifyAccountModalAtom);
    const [passwordValidationError, setPasswordValidationError] = useState('');

    function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
        const newPassword = e.target.value;
        setModifyPassword(newPassword);
        setPasswordValidationError(validatePassword(newPassword) ?? '');
    }

    const toggle_permission = (permission_key: 'view_devices' | 'register_devices' | 'manage_accounts') => {
        if (selectedAccount != null) {
            var permissions = {...selectedAccount.permissions}
            permissions[permission_key] = !permissions[permission_key]
            setRecoil(selectedAccountStateAtom, {
                ...selectedAccount,
                permissions: permissions
            }
            )
        }
    }

    const onFormSubmitModifyAccount: React.FormEventHandler<HTMLFormElement> = (e : React.FormEvent) => {
        e.preventDefault();
        if (selectedAccount !== null) {
            const target = e.target as

 typeof e.target & {
                modifyView: { checked: boolean },
                modifyInstall: { checked: boolean },
                modifyAdmin: { checked: boolean },
            };
            account_controller.modify_account(
                selectedAccount.id,
                target.modifyView.checked,
                target.modifyInstall.checked,
                target.modifyAdmin.checked,
                target.modifyAdmin.checked,
                target.modifyView.checked,
                target.modifyInstall.checked
            )
        }
        setModifyAccountModal(false);
    }

    if (!selectedAccount) {
        return <></>
    }
    
    return <Modal isOpen={true}>
            <ModalHeader toggle={() => { 
                setModifyAccountModal(!modifyAccountModal);
                setRecoil(selectedAccountStateAtom, null);
                 }} style={{fontSize: '1.6em'}}>
                {t('Modify Account')}
            </ModalHeader>
            <ModalBody>
                <legend className={css(accountsPageStyle.form_title)}>{t('Name')}</legend>
                <CardText>{selectedAccount.first_name} {selectedAccount.last_name}</CardText>
                <legend className={css(accountsPageStyle.form_title)}>{t('Phone Number')}</legend>
                <CardText>{selectedAccount.phone_number === '0' ? 'Na' : selectedAccount.phone_number}</CardText>
                <legend className={css(accountsPageStyle.form_title)}>{t('Email')}</legend>
                <CardText>{selectedAccount.email}</CardText>
                {/* submit */}
                <Form onSubmit={onFormSubmitModifyAccount}>
                    <legend className={css(accountsPageStyle.form_title)}>{t('Change Permissions')}</legend>
                    <FormGroup check>
                        <Label check>
                            <Input id="modifyView" type="checkbox"
                                defaultChecked={selectedAccount.permissions.view_devices}
                                onChange={() => { toggle_permission('view_devices'); }}
                            />
                            {t('View')}
                            &nbsp;-&nbsp;
                            {t("can view device data, make notes and add labels, can't view accounts or modify device settings")}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input id="modifyInstall" type="checkbox"
                                defaultChecked={selectedAccount.permissions.register_devices}
                                onChange={() => { toggle_permission('register_devices'); }} />
                            {t('Install')}
                            &nbsp;-&nbsp;
                            {t('all permissions associated with View, additionally able to setup devices via mobile app')}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>

                            <Input id="modifyAdmin" type="checkbox"
                                defaultChecked={selectedAccount.permissions.manage_accounts}
                                onChange={() => { toggle_permission('manage_accounts'); }} />
                            {t('Admin')}
                            &nbsp;-&nbsp;
                            {t('all permissions associated with Install, additionally able to view, create and manage accounts, as well as adjust device settings')}
                        </Label>
                    </FormGroup>
                    <Button type="submit" className={css(accountsPageStyle.submit_button_style)}>{t('Save')}</Button>
                </Form>
                {/* delete */}
                <Button className={css(accountsPageStyle.delete_button_style)}
                    onClick={() => { setModifyAccountModal(false); setRecoil(deleteModalAtom,true) }}>
                    {t('Delete')}
                </Button>
                <br />
                <br />
                {/** modify password */}
                <legend className={css(accountsPageStyle.form_title)}>{t('Change Password')}</legend>
                <CardText>
                    <Input type="password" placeholder={t("New Password")} onChange={handlePasswordChange}></Input>
                    <p className="password-alert">
                    {passwordValidationError}
                    </p>
                    <Label for="newPassword">
                        <a className={css(accountsPageStyle.link)} href="https://device-service.manhole-metrics.com/redirect-to-password-policy">{t('View our Password Policy')}</a>
                    </Label>
                </CardText>
                <Button className={css(accountsPageStyle.submit_button_style)}
                    disabled={passwordValidationError ? true : false}
                    onClick={() => {
                        if (!passwordValidationError) {
                          account_controller.change_account_password(selectedAccount.id, modifyPassword);
                          setModifyAccountModal(false)
                        }
                    }}>{t('Save')}</Button>
            </ModalBody>
        </Modal>
})

export const DeleteModalComponent = memo((_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const { t } = useTranslation();
    const selectedAccount = useRecoilValue(selectedAccountStateAtom);

    return <Modal isOpen={true} className={css(accountsPageStyle.delete_model_style)}>
    <ModalHeader className={css(accountsPageStyle.delete_model_header)}>{t('Confirm Deletion')}</ModalHeader>
    <ModalBody>
        <Row>
            <Col xs="6" className="d-flex">
                <Button className={css(accountsPageStyle.cancel_button_style)}
                    onClick={() => {
                        setRecoil(deleteModalAtom, false)
                    }}
                >
                    {t('Cancel')}
                </Button></Col>
            <Col xs="6" className="d-flex">
                <Button className={css(accountsPageStyle.confirm_delete_button_style)}
                    onClick={() => {
                        if (selectedAccount == null) { return };
                        account_controller.delete_account(selectedAccount.id);
                        setRecoil(modifyAccountModalAtom, false);
                        setRecoil(deleteModalAtom, false);
                    }}>
                    {t('Delete')}
                </Button>
            </Col>
        </Row>
    </ModalBody>
</Modal>
})