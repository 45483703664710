import { css } from "aphrodite";
import { deviceInfoPanelStyles } from "../../styles/device_metrics_page_styles";
import { useTranslation } from "../../../contexts/TranslationContext";
import { ComposedChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { bgcolor } from "@mui/system";
import { card_background_colour } from "../../styles/style_variables";
import { getRecoil } from "recoil-nexus";
import { languageAtom } from "../../../models/atoms/ui_atoms";
import { appStyles } from "../../styles/app_styles";

const formatDate = (date: number, language: string, date_format: Intl.DateTimeFormatOptions) => {
	const numericDate = new Date(date * 1000);
	return numericDate.toLocaleDateString(language, date_format);
};

const computeTicks = (data: any) => {
	if (!data || !data.times || data.times.length === 0) {
		return [];
	}
	return [data.times[0], data.times[Math.floor(data.times.length / 2)], data.times[data.times.length - 1]];
}

const renderTooltip = (show_water_level: boolean, show_rainfall_level: boolean) => (props: any) => {
	const { t, language, distance } = useTranslation();
	console.log(JSON.stringify(props));
	if (props.payload.length === 0 || (props.payload[0].payload.fake_water_level && props.payload[0].payload.fake_rainfall_level) || (props.payload[0].payload.fake_water_level && !show_rainfall_level) || (props.payload[0].payload.fake_rainfall_level && !show_water_level)) {
		return <></>
	}

	return <div style={{ backgroundColor: "white", padding: '4px', borderColor: '#000099', border: 'ridge', borderWidth: '1' }}>
		<p>{formatDate(props.label, language, { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}</p>
		{show_water_level && !props.payload[0].payload.fake_water_level && <p style={{ color: '#000099' }}>{t('Water Level:')} {distance.show(distance.to_metric(props.payload[0].payload.water_level))}</p>}
		{show_rainfall_level && !props.payload[0].payload.fake_rainfall_level && <p style={{ color: '#009900' }}>{t('Rainfall Level:')} {distance.show(distance.to_metric(props.payload[0].payload.rainfall_level))}</p>}
	</div>
}

const replaceNegative = (x: number, r: number | null): number | null => {
	if (x >= 0) {
		return x;
	} else {
		return r;
	}
}

export const DeviceGraph = (props: any) => {
	const { t, language, distance } = useTranslation();
	//const data = props.data.map((x: any) => { return {rainfall_level_mm: 0, water_level_mm: 0, ...x}})
	const data = [];
	if (props.data) {
		var last_water_level = null;
		for (let i = 0; i < props.data.times.length; i++) {
			if (props.data.water_levels[i] >= 0) {
				last_water_level = props.data.water_levels[i];
				break;
			}
		}
		for (let i = 0; i < props.data.times.length; i++) {
			let water_level = replaceNegative(props.data.water_levels[i], last_water_level);
			last_water_level = water_level;
			data.push({
				time: props.data.times[i],
				water_level: distance.from_metric(water_level ?? 0),
				rainfall_level: distance.from_metric(replaceNegative(props.data.rainfall_levels[i], 0) ?? 0),
				fake_water_level: props.data.water_levels[i] < 0,
				fake_rainfall_level: props.data.rainfall_levels[i] < 0,
			});
		}
	}
	console.log(data)
	return (<div>
		{props.title && <p className={css(deviceInfoPanelStyles.subsection_text)}> <span style={{ fontWeight: 500 }}>{t(props.title)}</span></p>}
		<ResponsiveContainer width={'100%'} height={300} style={{ paddingRight: props.show_rainfall && props.show_water_level ? 0 : 70 }}>
			<ComposedChart height={300} className={css(deviceInfoPanelStyles.chart)} data={data}>
				<CartesianGrid stroke="#ccc" />
				<Tooltip
					isAnimationActive={false}
					content={renderTooltip(props.show_water_level, props.show_rainfall)}
				/>
				<XAxis xAxisId="time" dataKey="time" tickFormatter={(date) => formatDate(date, language, { day: 'numeric', month: 'short', year: 'numeric' })} type="number" scale="time" domain={['auto', 'auto']} ticks={computeTicks(props.data)} />
				{props.show_water_level && <>
					<Line isAnimationActive={false} type="monotone" yAxisId="water" xAxisId="time" dataKey="water_level" stroke="rgb(0, 0, 153)" strokeWidth="3px" dot={false} />
					<YAxis yAxisId="water" dataKey="water_level" width={70} stroke="rgb(0, 0, 153)" domain={[0, distance.from_metric(props.selectedDevice.backend_max_height_mm)]} scale='linear'></YAxis>
				</>}
				{props.show_rainfall && <>
					<Line isAnimationActive={false} type="monotone" yAxisId="rainfall" xAxisId="time" dataKey="rainfall_level" stroke="rgb(0, 153, 0)" strokeWidth="3px" dot={false} />
					<YAxis yAxisId="rainfall" dataKey="rainfall_level" stroke="rgb(0, 153, 0)" orientation={props.show_water_level ? "right" : "left"} width={70} domain={[0, "dataMax + 1"]} scale='linear'></YAxis>
				</>}
			</ComposedChart>
		</ResponsiveContainer>
	</div>);
}
