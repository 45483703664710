import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import en_GB from '../Locales/en-GB.json';
import en_US from '../Locales/en-US.json';
import de from '../Locales/de.json';


interface Translations {
	[key: string]: string;
}

interface TranslationContextType {
	t: (key: string, values?: Record<string, string>) => string;
	changeLanguage: (lang: string) => void;
	language: string;
	distance: Distance;
	temperature: Temperature;
}

const translations: Record<string, Translations> = {
	'en-GB': en_GB,
	'en-US': en_US,
	'de': de,
};

interface Distance {
	show(distance_mm: number): string;
	to_metric(distance: number): number;
	from_metric(distance_mm: number): number;
	from(distance: string): number;
}

class MetricDistance implements Distance {
	show(distance_mm: number): string {
		return `${distance_mm}mm`;
	}
	from(distance: string): number {
		return Number(distance);
	}
	to_metric(distance: number): number {
		return distance;
	}
	from_metric(distance_mm: number): number {
		return distance_mm;
	}
};

class ImperialDistance implements Distance {
	show(distance_mm: number): string {
		const distance_inches = distance_mm / 25.4;
		const distance_ft = Math.floor(distance_inches / 12);
		if (distance_ft > 1) {
			return `${distance_ft}' ${(distance_inches % 12).toFixed(1)}"`
		}
		return `${(distance_mm / 25.4).toFixed(1)}"`;
	}
	from(distance: string): number {
		return Number(distance) * 25.4;
	}
	to_metric(distance: number): number {
		return distance * 25.4;
	}
	from_metric(distance_mm: number): number {
		return distance_mm / 25.4;
	}
}

interface Temperature {
	show(temp_c: number): string;
	from(temp: string): number;
}

class Celsius implements Temperature {
	show(temp_c: number): string {
		return `${temp_c}\°C`;
	}
	from(temp: string): number {
		return Number(temp);
	}
}

class Farenheit implements Temperature {
	show(temp_c: number): string {
		return `${((temp_c * (9 / 5)) + 32).toFixed(1)}°F`;
	}
	from(temp: string): number {
		return (Number(temp) - 32) * (5 / 9);
	}
}

const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [language, setLanguage] = useState<string>('en-GB');
	const [currentTranslations, setCurrentTranslations] = useState<Translations>(translations['en-GB']);
	const [distance, setDistance] = useState<Distance>(new MetricDistance());
	const [temperature, setTemperature] = useState<Temperature>(new Celsius());
	useEffect(() => {
		if (language !== 'en-GB' && language !== 'en-US' && language !== 'de') {
			setLanguage('en-GB');
			return;
		}
		if (language === "en-US") {
			setDistance(new ImperialDistance());
			setTemperature(new Farenheit());
		} else {
			setDistance(new MetricDistance());
			setTemperature(new Celsius());
		}
		setCurrentTranslations(translations[language]);
	}, [language]);

	const t = (key: string, values?: Record<string, string>): string => {
		let translation = currentTranslations[key] || key;
		if (values) {
			for (let placeholder in values) {
				translation = translation.replace(`{${placeholder}}`, values[placeholder]);
			}
		}
		return translation;
	};

	const changeLanguage = (lang: string): void => {
		setLanguage(lang);
	};

	return (
		<TranslationContext.Provider value={{ t, changeLanguage, language, distance, temperature }}>
			{children}
		</TranslationContext.Provider>
	);
};

export const useTranslation = (): TranslationContextType => {
	const context = useContext(TranslationContext);
	if (!context) {
		throw new Error('useTranslation must be used within a TranslationProvider');
	}
	return context;
};
