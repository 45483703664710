// Function to calculate signal strength percentage based on received string "+CESQ: 99,99,255,255,27,65"
const max_received_quality = 34;
const max_received_power = 97;
const max_time_for_signal_check = 10;

const max_received_quality_percentage = 50;
const max_received_power_percentage = 50;
const max_time_for_signal_check_percentage = 0;

// function to calculate signal strength percentage based on received string "+CESQ: 99,99,255,255,27,65" and signal time
export const getSignalStrengthPercentage = (signal_strength: string, signal_check_time: number) => {
	console.log('signal_strength', signal_strength);
	const array = signal_strength.split(',');

	const signal_quality = Number(array[array.length - 2])
	const received_power = Number(array[array.length - 1])

	const received_quality_percentage = (signal_quality / max_received_quality) * max_received_quality_percentage;
	const received_power_percentage = (received_power / max_received_power) * max_received_power_percentage;
	const signal_check_time_percentage = max_time_for_signal_check_percentage - ((signal_check_time / 60) / max_time_for_signal_check) * max_time_for_signal_check_percentage;

	return Math.floor(received_quality_percentage + received_power_percentage + signal_check_time_percentage)
}

export const getSignalStrengthMapping = (percentage: number): string[] => {
	if (percentage < 25) {
		return ['Bad', '#cc3300'];
	} else if (percentage >= 25 && percentage < 75) {
		return ['Good', '#dfdf07'];
	} else if (percentage >= 75 && percentage <= 100) {
		return ['Excellent', '#00cc00'];
	} else {
		return ['Error', '#cc3300'];
	}
};

// Combined function to get signal strength value
export const get_signal_strength_value = (signal: string, signal_check_time: number): [string, number, string] => {
	const percentage = getSignalStrengthPercentage(signal, signal_check_time);

	if (isNaN(percentage)) {
		return ['Error', 0, '#cc3300'];
	}

	const [label, color] = getSignalStrengthMapping(percentage);
	return [label, percentage, color];
};



export const unix_timestamp_to_date_time = (unixTimestamp: number, useTime: boolean, useDate: boolean, chart_time: boolean, language: string) => {
	const options: Intl.DateTimeFormatOptions = {
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		year: useDate ? 'numeric' : undefined,
		month: useDate ? 'short' : undefined,
		day: useDate ? 'numeric' : undefined,
		hour: useTime ? '2-digit' : undefined,
		minute: useTime ? '2-digit' : undefined,
		second: useTime ? '2-digit' : undefined,
		hour12: false
	};
	const milliseconds = unixTimestamp * 1000;
	const date = new Date(milliseconds);
	const localDateTimeString = date.toLocaleString(language, options);
	return localDateTimeString.replace(/,|at| AM| PM/g, '')
};


export function alphanumeric_comparator(a: string, b: string) {
	const aLower = [...a.toLowerCase().match(/[a-zA-Z\s.,\/\-_!@£$%)(?+=]+|[0-9]+/g) ?? []];
	const bLower = [...b.toLowerCase().match(/[a-zA-Z\s.,\/\-_!@£$%)(?+=]+|[0-9]+/g) ?? []];
	const max = Math.min(aLower.length, bLower.length);
	for (let i = 0; i < max; i++) {
		var aNum = parseInt(aLower[i]);
		var bNum = parseInt(bLower[i]);
		var aVal = isNaN(aNum) ? aLower[i] : aNum;
		var bVal = isNaN(bNum) ? bLower[i] : bNum;
		if (aVal < bVal) {
			return -1;
		} else if (aVal > bVal) {
			return 1;
		}
	}
	return aLower.length - bLower.length;
}

export function toHour(s: number): string {
	let result = s / 3600;
	if (result.toFixed(1).includes(".0")) {
		return Math.floor(result).toString()
	}
	else {
		return result.toFixed(1)
	}

}

export function toDays(s: number): string {
	let result = s / (3600 * 24);
	if (result.toFixed(1).includes(".0")) {
		return Math.floor(result).toString();
	} else {
		return result.toFixed(1);
	}
}

export function toMinute(s: number): string {
	let result = s / 60;
	if (result.toFixed(1).includes(".0")) {
		return Math.floor(result).toString()
	}
	else {
		return result.toFixed(1)
	}
}


export const calculateAverageFillPercentage = (devices_list: any, blind_distance: number): number => {
	if (!devices_list.length) {
		return 0
	}

	const fill_percentages_list = devices_list.map((device: any) => {
		if (Math.round((device.current_level_percentage * device.max_distance_mm) / (device.max_distance_mm - blind_distance)) <= 100) {
			return Math.round((device.current_level_percentage * device.max_distance_mm) / (device.max_distance_mm - blind_distance))
		}
		else {
			return 100
		}
	});
	return Math.floor((fill_percentages_list.reduce((sum: number, a: number) => sum + a, 0)) / devices_list.length);
}

export const calculateAverageBattery = (devices_list: any): number => {
	if (!devices_list.length) {
		return 0
	}
	const battery_percentages_list = devices_list.map((device: any) => device.battery_percentage);
	return Math.floor((battery_percentages_list.reduce((sum: number, a: number) => sum + a, 0)) / devices_list.length);
}


export const calculateMissingMessagesDevices = (device_list: any): number => {
	let result = 0
	if (!device_list.length) {
		return result
	}

	device_list.forEach((device: any) => {
		if (device.session_missed_transmissions.length > 0) {
			result += 1
		}

	});



	return result
}
